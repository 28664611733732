import React, {useState} from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {names, colors, daysPerPerson, initialDate} from './data'

const App = () => {
    // Mantendo a data atual como o valor inicial
    const [date, setDate] = useState(new Date());

    const calcPerson = (data) => {
        const diffDays = Math.floor((data - initialDate) / (1000 * 60 * 60 * 24));
        const index = Math.floor(diffDays / daysPerPerson) % names.length;
        return {name: names[index], cor: colors[index]};
    };

    const tileContent = ({date, view}) => {
        if (view === 'month') {
            const {name, cor} = calcPerson(date);
            return <p className='name-text' style={{color: cor, fontWeight: 'bold'}}>{name}</p>;
        }
    };

    return (
        <div style={{
            paddingTop: '24px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <h1 style={{color: '#6f48eb'}}>Quem dorme no Rio </h1>
            <Calendar
                onChange={setDate}
                value={date}
                tileContent={tileContent}
                locale="pt-BR"
                style={{width: '80%', maxWidth: '600px'}}
            />
        </div>
    );
};

export default App;
